@import "../../../styles/base/abstracts";

.hbs-hours-table {
  color: $c-text;
  display: block;
  padding: $spacing-lg $spacing-lg $spacing-xxs;
}

.hbs-hours-table-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: $border-solid;
  padding-bottom: $spacing-sm;

  @include mq($bp-tablet) {
    border-bottom: none;
  }
}

.hbs-hours-table-header__text {
  @include mq($bp-tablet) {
    align-items: baseline;
    display: flex;
  }
}

.hbs-hours-table__title {
  line-height: $lh-base;
}

.hbs-hours-table__subtitle {
  @include small-text;
  color: $c-text-lighter;
  @include mq($bp-tablet) {
    margin-left: $spacing-xxs;
    padding: $spacing-xxxs;
  }
}

.hbs-hours-table__info-items {
  @include mq($bp-tablet) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: $grid-padding * 4;
  }

  @include mq($bp-desktop) {
    grid-template-columns: repeat(4, 1fr);
    column-gap: $grid-padding * 2;
  }
}

.hbs-hours-table__info-item {
  @include padding($spacing-md x $spacing-lg);
  @include small-text;
  display: flex;
  flex-direction: column;
  gap: $spacing-xxxs;

  &:not(:last-child) {
    border-bottom: $border-solid;
  }

  p {
    margin: 0 !important;
  }

  @include mq($bp-tablet) {
    border-bottom: none !important;
    border-top: $border-solid;
  }
}

.hbs-hours-table__info-header {
  display: flex;
  justify-content: space-between;
}

.hbs-hours-table__info-heading {
  @include xs-title;
  flex: 1;
  padding-right: $spacing-xxs;
}

.hbs-hours-table__info-locations {
  max-width: $col-10;
}

// Springshare Table Styles

.hbs-hours-table {
  .s-lc-w-sr-only {
    @include hide;
  }

  .s-lc-w-btn,
  .s-lc-w-head-pre,
  thead,
  .s-lc-w-today-view-all {
    display: none;
  }

  .s-lc-w-head {
    text-align: left;
  }

  tbody {
    @include mq($bp-tablet) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: $grid-padding * 3;
    }

    @include mq($bp-desktop) {
      grid-template-columns: repeat(5, 1fr);
      column-gap: $grid-padding * 1.5;
    }
  }

  .s-lc-w-times {
    @include padding($spacing-md x $spacing-lg);
    border-top: $border-solid;
    display: flex;
    justify-content: space-between;

    @include mq($bp-tablet) {
      flex-direction: column;
    }

    &:first-of-type {
      border-top: none;

      @include mq($bp-tablet) {
        border-top: $border-solid;
      }
    }
  }

  .s-lc-w-name,
  .s-lc-w-time {
    flex: 1;

    @include mq($bp-tablet) {
      display: block;
    }
  }

  .s-lc-w-name {
    @include xs-title;
    margin-bottom: $spacing-xxxs;
  }

  .s-lc-w-time {
    display: flex;
    justify-content: flex-end;
    @include small-text;

    @include mq($bp-tablet) {
      flex-direction: column;
    }
  }
}
